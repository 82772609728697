import webFontLoader from 'webfontloader';

webFontLoader.load({
  google: {
    families: [
      'Open Sans:400,600,700',
      'Fira Sans:400,600,700',
    ],
  },
  custom: {
    families: ['FontAwesome'],
    // testStrings: {
    //   FontAwesome: '\ue800',
    // },
    // urls: [
    //   `${process.env.BASE_URL}assets/fonts/FontAwesome/fontawesome.css`,
    // ],
  },
  timeout: 15000,
});

